














import Vue from 'vue'
import { synergyLocations } from '@/assets/enums'

export default Vue.extend({
  name: 'synergy-selector',
  props: {
    item: {
      type: Object,
      required: true,
    },
    npc: { type: Boolean },
  },
  data: () => ({
    synergyLocations: synergyLocations,
  }),
})
