var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "caption px-1"
  }, [_vm._v(_vm._s(_vm.title))]), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.npc,
      expression: "npc"
    }],
    staticClass: "caption text--disabled"
  }, [_vm._v(" The syntax "), _c('code', [_vm._v("{X/Y/Z}")]), _vm._v(" will be rendered into the values for Tier 1/2/3 based on user tier selection within COMP/CON ")]), _c('tiptap-vuetify', {
    attrs: {
      "id": "rte",
      "extensions": _vm.extensions,
      "card-props": {
        flat: true,
        tile: true,
        elevation: 0
      },
      "toolbar-attributes": {
        color: 'darkgrey',
        dark: true
      }
    },
    model: {
      value: _vm.val,
      callback: function callback($$v) {
        _vm.val = $$v;
      },
      expression: "val"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }