


















































































import { ITalentData, ITalentRankData } from '@tenebrae-press/lancer-types'
import Vue from 'vue'

type TalentRankBuilderData = ITalentRankData & {
  isEdit: boolean
  editIndex: number
  dialog: boolean
}

export default Vue.extend({
  name: 'rank-builder',
  props: { item: { type: Object as () => ITalentData, required: true } },

  data: (): TalentRankBuilderData => ({
    id: '',
    dialog: false,
    name: '',
    description: '',
    synergies: [],
    actions: [],
    bonuses: [],
    deployables: [],
    counters: [],
    integrated: [],
    exclusive: false,
    isEdit: false,
    editIndex: -1,
  }),
  computed: {
    confirmOK(): boolean {
      return !!this.name && !!this.description
    },
  },
  methods: {
    newItem(): void {
      this.reset()
      this.dialog = true
    },
    submit(): void {
      const e: ITalentRankData = {
        name: this.name,
        description: this.description,
        synergies: this.synergies,
        actions: this.actions,
        bonuses: this.bonuses,
        deployables: this.deployables,
        counters: this.counters,
        integrated: this.integrated,
        exclusive: this.exclusive,
      }
      if (this.isEdit) {
        this.$set(this.item.ranks, this.editIndex, e)
      } else {
        if (!this.item.ranks) this.$set(this.item, 'ranks', [])
        this.item.ranks.push(e)
      }
      this.reset()
      this.dialog = false
    },
    edit(rank: ITalentRankData, index: number): void {
      this.reset()
      this.name = rank.name
      this.description = rank.description
      this.actions = rank.actions
      this.bonuses = rank.bonuses
      this.synergies = rank.synergies
      this.deployables = rank.deployables
      this.counters = rank.counters
      this.integrated = rank.integrated
      this.exclusive = rank.exclusive
      this.isEdit = true
      this.editIndex = index
      this.dialog = true
    },
    remove(index: number): void {
      this.item.ranks.splice(index, 1)
    },
    reset(): void {
      this.name = ''
      this.description = ''
      this.synergies = []
      this.actions = []
      this.bonuses = []
      this.synergies = []
      this.deployables = []
      this.counters = []
      this.integrated = []
      this.exclusive = false
      this.isEdit = false
    },
  },
})
