var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('v-app-bar', {
    attrs: {
      "app": "",
      "color": "secondary",
      "dark": ""
    }
  }, [_c('v-menu', {
    staticClass: "pr-12",
    attrs: {
      "bottom": "",
      "offset-y": "",
      "max-height": "50vh",
      "min-width": "30vw"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-menu")])], 1)];
      }
    }])
  }, [_c('v-list', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("mdi-home")])], 1), _c('v-list-item-title', [_vm._v("Home")])], 1), _c('v-list-item', {
    attrs: {
      "to": "/about"
    }
  }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("mdi-information-outline")])], 1), _c('v-list-item-title', [_vm._v("About")])], 1), _c('v-list-item', {
    attrs: {
      "to": "/util"
    }
  }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("mdi-wrench")])], 1), _c('v-list-item-title', [_vm._v("Utilities")])], 1), _vm.loaded ? _c('v-divider') : _vm._e(), _vm.loaded ? _c('v-list-item-group', [_vm._l(_vm.categories, function (c) {
    return _c('v-list-item', {
      key: c,
      attrs: {
        "to": "/editor/".concat(c)
      }
    }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-list-item-title', {
      staticStyle: {
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(c.replace('_', ' ')) + " ")])], 1);
  }), _c('v-divider'), _vm._l(_vm.gmCategories, function (c) {
    return _c('v-list-item', {
      key: c,
      attrs: {
        "to": "/editor/".concat(c)
      }
    }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-list-item-title', {
      staticStyle: {
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(c.replace('_', ' ')) + " ")])], 1);
  })], 2) : _vm._e()], 1)], 1), _c('v-divider', {
    staticClass: "ml-2 mr-4",
    attrs: {
      "vertical": ""
    }
  }), _c('h2', [_vm._v("COMP/CON LCP Editor v.0.2")]), _c('h3', {
    staticClass: "warning--text pl-2"
  }, [_vm._v("[EXPERIMENTAL]")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "to": "/",
      "active-class": "indigo"
    }
  }, [_c('v-icon', [_vm._v("mdi-home")])], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "to": "/about",
      "active-class": "indigo"
    }
  }, [_c('v-icon', [_vm._v("mdi-information-outline")])], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "to": "/util",
      "active-class": "indigo"
    }
  }, [_c('v-icon', [_vm._v("mdi-wrench")])], 1)], 1), _c('v-main', [_c('router-view')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }