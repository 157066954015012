

































































































































import {
  DamageType,
  IActionData,
  IBonusData,
  ICounterData,
  IDamageData,
  IDeployableData,
  IRangeData,
  ISynergyData,
  ITagData,
  IWeaponProfileData,
  WeaponSize,
  WeaponType,
  WEAPON_SIZES,
  WEAPON_TYPES,
} from '@tenebrae-press/lancer-types'

type ProfileBuilderData = {
  dialog: boolean
  weaponTypes: Array<WeaponType>
  weaponSizes: Array<WeaponSize>
  name: string
  description: string
  effect: string
  on_attack: string
  on_hit: string
  on_crit: string
  type: DamageType
  cost: number
  damage: Array<IDamageData>
  range: Array<IRangeData>
  actions: Array<IActionData>
  bonuses: Array<IBonusData>
  synergies: Array<ISynergyData>
  deployables: Array<IDeployableData>
  counters: Array<ICounterData>
  integrated: Array<string>
  special_equipment: Array<string>
  tags: Array<ITagData>
  isEdit: boolean
  editIndex: number
}

import Vue from 'vue'
export default Vue.extend({
  name: 'weapon-profile-editor',
  props: {
    item: { type: Object, required: true },
  },
  data: (): ProfileBuilderData => ({
    dialog: false,
    weaponTypes: WEAPON_TYPES,
    weaponSizes: WEAPON_SIZES,
    name: '',
    description: '',
    effect: '',
    on_attack: '',
    on_hit: '',
    on_crit: '',
    type: 'Variable',
    cost: 1,
    damage: [],
    range: [],
    actions: [],
    bonuses: [],
    synergies: [],
    deployables: [],
    counters: [],
    integrated: [],
    special_equipment: [],
    tags: [],
    isEdit: false,
    editIndex: -1,
  }),
  computed: {
    confirmOK(): boolean {
      return !!this.name
    },
  },
  methods: {
    open() {
      this.reset()
      this.dialog = true
    },
    close() {
      this.reset()
      this.dialog = false
    },
    submit(): void {
      const e: IWeaponProfileData = {
        name: this.name,
        description: this.description,
        effect: this.effect,
        on_attack: this.on_attack,
        on_hit: this.on_hit,
        on_crit: this.on_crit,
        type: this.type,
        cost: this.cost,
        damage: this.damage,
        range: this.range,
        actions: this.actions,
        bonuses: this.bonuses,
        synergies: this.synergies,
        deployables: this.deployables,
        counters: this.counters,
        integrated: this.integrated,
        special_equipment: this.special_equipment,
        tags: this.tags,
      }
      if (!this.item.profiles) this.$set(this.item, 'profiles', [])
      if (this.isEdit && this.editIndex > -1) {
        this.$set(this.item.profiles, this.editIndex, e)
      } else this.item.profiles.push(e)
      this.reset()
      this.dialog = false
    },
    edit(weapon: ProfileBuilderData, index: number): void {
      this.name = weapon.name
      this.description = weapon.description
      this.effect = weapon.effect
      this.on_attack = weapon.on_attack
      this.on_hit = weapon.on_hit
      this.on_crit = weapon.on_crit
      this.cost = weapon.cost
      this.type = weapon.type
      this.damage = weapon.damage
      this.range = weapon.range
      this.actions = weapon.actions
      this.bonuses = weapon.bonuses
      this.synergies = weapon.synergies
      this.deployables = weapon.deployables
      this.counters = weapon.counters
      this.integrated = weapon.integrated
      this.special_equipment = weapon.special_equipment
      this.tags = weapon.tags
      this.isEdit = true
      this.editIndex = index
      this.dialog = true
    },
    remove(index: number): void {
      this.dialog = false
      this.item.profiles.splice(index, 1)
    },
    reset(): void {
      this.name = ''
      this.description = ''
      this.effect = ''
      this.on_attack = ''
      this.on_hit = ''
      this.on_crit = ''
      this.cost = 1
      this.type = 'Variable'
      this.damage = []
      this.range = []
      this.actions = []
      this.bonuses = []
      this.synergies = []
      this.deployables = []
      this.counters = []
      this.integrated = []
      this.special_equipment = []
      this.tags = []
      this.isEdit = false
      this.editIndex = -1
    },
  },
})
