









import Vue from 'vue'
import uuid from 'uuid/v4'

export default Vue.extend({
  name: 'id-entry',
  props: ['value'],
  computed: {
    val: {
      get(): string {
        return `${this.value ?? ''}`
      },
      set(val: string) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    uuid(): void {
      this.$set(this, 'val', uuid())
    },
  },
})
