var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "caption mb-n1 mt-n3"
  }, [_vm._v("BONUSES")]), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm._l(_vm.item.bonuses, function (bonus, i) {
    return _c('v-tooltip', {
      key: "bonus_chip_".concat(_vm.item.id, "-").concat(i),
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('v-chip', _vm._g({
            staticClass: "mx-1",
            attrs: {
              "small": "",
              "close": "",
              "outlined": "",
              "close-icon": "mdi-close"
            },
            on: {
              "click": function click($event) {
                return _vm.edit(bonus, i);
              },
              "click:close": function clickClose($event) {
                return _vm.remove(i);
              }
            }
          }, on), [_vm._v(" " + _vm._s(bonus.id) + ", " + _vm._s(bonus.val) + " ")])];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(_vm.descById(bonus.id)) + ": " + _vm._s(bonus.val) + " "), _c('v-divider'), _vm.restrictions(bonus).length ? _c('div', [_vm._v("Restricted to: " + _vm._s(_vm.restrictions(bonus)))]) : _vm._e(), bonus.overwrite ? _c('div', [_vm._v(" Overwrite: "), _c('b', {
      staticClass: "warning--text"
    }, [_vm._v("true")])]) : _vm._e(), bonus.replace ? _c('div', [_vm._v(" Replace: "), _c('b', {
      staticClass: "warning--text"
    }, [_vm._v("true")])]) : _vm._e()], 1);
  }), _c('v-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var attrs = _ref2.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.newItem();
            }
          }
        }, 'v-btn', attrs, false), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "pink darken-4"
    }
  }, [_vm._v("Add Bonus")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "justify": "space-around",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Bonus",
      "items": _vm.npc ? _vm.npcBonuses : _vm.bonuses,
      "return-object": "",
      "item-text": "desc"
    },
    model: {
      value: _vm.bonus,
      callback: function callback($$v) {
        _vm.bonus = $$v;
      },
      expression: "bonus"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [!_vm.bonus ? _c('div', {
    staticClass: "text--disabled"
  }, [_vm._v("Select Bonus")]) : _vm.bonus.type === 'integer' ? _c('div', [_vm.npc ? _c('tiered-stat-input', {
    attrs: {
      "title": "Integer"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }) : _c('v-text-field', {
    attrs: {
      "label": "Integer",
      "outlined": ""
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1) : _vm.bonus.type === 'boolean' ? _c('div', [_c('v-radio-group', {
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_c('v-radio', {
    attrs: {
      "value": "true",
      "label": "True"
    }
  }), _c('v-radio', {
    attrs: {
      "value": "false",
      "label": "False"
    }
  })], 1)], 1) : _c('div', [_vm.npc ? _c('tiered-stat-input', {
    attrs: {
      "title": "String"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }) : _c('v-text-field', {
    attrs: {
      "label": "String",
      "outlined": ""
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)])], 1), _c('v-divider'), _c('div', {
    staticClass: "caption"
  }, [_vm._v("Restrict to:")]), _c('v-row', {
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.damageType,
      "dense": "",
      "outlined": "",
      "multiple": "",
      "hide-details": "",
      "label": "Damage Types"
    },
    model: {
      value: _vm.dt,
      callback: function callback($$v) {
        _vm.dt = $$v;
      },
      expression: "dt"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.rangeType,
      "dense": "",
      "outlined": "",
      "multiple": "",
      "hide-details": "",
      "label": "Range Types"
    },
    model: {
      value: _vm.rt,
      callback: function callback($$v) {
        _vm.rt = $$v;
      },
      expression: "rt"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.weaponType,
      "dense": "",
      "outlined": "",
      "multiple": "",
      "hide-details": "",
      "label": "Weapon Types"
    },
    model: {
      value: _vm.wt,
      callback: function callback($$v) {
        _vm.wt = $$v;
      },
      expression: "wt"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.weaponSize,
      "dense": "",
      "outlined": "",
      "multiple": "",
      "hide-details": "",
      "label": "Weapon Sizes"
    },
    model: {
      value: _vm.ws,
      callback: function callback($$v) {
        _vm.ws = $$v;
      },
      expression: "ws"
    }
  })], 1)], 1), _c('v-divider'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "top": "",
      "max-width": "50vw"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_c('span', _vm._g({}, on), [_c('v-switch', {
          attrs: {
            "label": "Overwite"
          },
          model: {
            value: _vm.overwrite,
            callback: function callback($$v) {
              _vm.overwrite = $$v;
            },
            expression: "overwrite"
          }
        })], 1)];
      }
    }])
  }, [_vm._v(" The overwrite flag will overwrite any integer value bonus with the highest bonus of the same type from any source that has an overwrite flag. Which is to say: all applicable bonuses of that bonus ID will be collected and everything will be discarded except for the bonus of the highest value that has an overwrite flag. Eg: a mech with items that give +4, +2, +3 (overwrite) and +2 (overwrite) AI Cap will result in a +3 AI Cap bonus. This flag is not necessary for non-integer values. ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "top": "",
      "max-width": "50vw"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on;
        return [_c('span', _vm._g({}, on), [_c('v-switch', {
          attrs: {
            "label": "Replace"
          },
          model: {
            value: _vm.replace,
            callback: function callback($$v) {
              _vm.replace = $$v;
            },
            expression: "replace"
          }
        })], 1)];
      }
    }])
  }, [_vm._v(" The replace flag will replace any integer value in the target item, pilot, or mech with the total collected replace bonus value. Eg. a mech with a base HP of 8 and the following bonuses: +1, +1 and 3 (replace) will result in a mech with a final HP of 5 (5 replaced by 3, +1 +1). A mech with a base HP of 8 and the following bonuses: +1, 3 (replace), and 3 (replace) will result in a mech with a final HP of 7 (5 replaced by 3 + 3, then +1). ")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("cancel")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "success darken-2",
      "disabled": !_vm.bonus || !_vm.value
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? 'save' : 'confirm') + " ")])], 1)], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }