var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-n2"
  }, [_c('div', {
    staticClass: "overline mb-n2 mt-n2"
  }, [_vm._v(_vm._s(_vm.title))]), _c('v-card', {
    staticClass: "pa-1 pt-2",
    attrs: {
      "color": "grey darken-3"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.val[0],
      "label": "T1",
      "type": "number",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": function change($event) {
        return _vm.setTier($event, 0);
      }
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.val[1],
      "label": "T2",
      "type": "number",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": function change($event) {
        return _vm.setTier($event, 1);
      }
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.val[2],
      "label": "T3",
      "type": "number",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": function change($event) {
        return _vm.setTier($event, 2);
      }
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }