

























































































import {
  Duration,
  IActionData,
  IBonusData,
  ICounterData,
  IDeployableData,
  IFrameTraitData,
  ISynergyData,
} from '@tenebrae-press/lancer-types'
import Vue from 'vue'

type IFrameTraitBuilderData = {
  dialog: boolean
  name: string
  use?: Duration
  description: string
  actions: Array<IActionData>
  bonuses: Array<IBonusData>
  counters: Array<ICounterData>
  deployables: Array<IDeployableData>
  synergies: Array<ISynergyData>
  integrated: Array<string>
  special_equipment: Array<string>
  isEdit: boolean
  editIndex: number
}

export default Vue.extend({
  name: 'trait-builder',
  props: { item: { type: Object, required: true } },

  data: (): IFrameTraitBuilderData => ({
    dialog: false,
    name: '',
    use: undefined,
    description: '',
    actions: [],
    bonuses: [],
    counters: [],
    deployables: [],
    synergies: [],
    integrated: [],
    special_equipment: [],
    isEdit: false,
    editIndex: -1,
  }),
  computed: {
    confirmOK(): boolean {
      return !!this.name && !!this.description
    },
  },
  methods: {
    newItem(): void {
      this.reset()
      this.dialog = true
    },
    submit(): void {
      const e: IFrameTraitData = {
        name: this.name,
        use: this.use,
        description: this.description,
        actions: this.actions,
        bonuses: this.bonuses,
        counters: this.counters,
        deployables: this.deployables,
        synergies: this.synergies,
        integrated: this.integrated,
        special_equipment: this.special_equipment,
      }
      if (this.isEdit) {
        this.$set(this.item.traits, this.editIndex, e)
      } else {
        if (!this.item.traits) this.$set(this.item, 'traits', [])
        this.item.traits.push(e)
      }
      this.reset()
      this.dialog = false
    },
    edit(trait: IFrameTraitData, index: number): void {
      this.reset()
      this.name = trait.name
      this.use = trait.use
      this.description = trait.description
      this.actions = trait.actions ?? []
      this.bonuses = trait.bonuses ?? []
      this.counters = trait.counters ?? []
      this.deployables = trait.deployables ?? []
      this.synergies = trait.synergies ?? []
      this.integrated = trait.integrated ?? []
      this.special_equipment = []
      this.isEdit = true
      this.editIndex = index
      this.dialog = true
    },
    remove(index: number): void {
      this.item.traits.splice(index, 1)
    },
    reset(): void {
      this.name = ''
      this.use = undefined
      this.description = ''
      this.actions = []
      this.bonuses = []
      this.counters = []
      this.deployables = []
      this.synergies = []
      this.integrated = []
      this.special_equipment = []
      this.isEdit = false
      this.editIndex = -1
    },
  },
})
