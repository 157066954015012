var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "caption mb-n1 mt-n3"
  }, [_vm._v("FRAME TRAITS")]), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm._l(_vm.item.traits, function (trait, i) {
    return _c('v-tooltip', {
      key: "trait_chip_".concat(_vm.item.id || _vm.item.name, "-").concat(i),
      attrs: {
        "top": "",
        "max-width": "50vw"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('v-chip', _vm._g({
            staticClass: "mx-1",
            attrs: {
              "small": "",
              "close": "",
              "outlined": "",
              "close-icon": "mdi-close"
            },
            on: {
              "click": function click($event) {
                return _vm.edit(trait, i);
              },
              "click:close": function clickClose($event) {
                return _vm.remove(i);
              }
            }
          }, on), [_vm._v(" " + _vm._s(trait.name) + " ")])];
        }
      }], null, true)
    }, [trait.use ? _c('b', [_vm._v(_vm._s(trait.use))]) : _vm._e(), _c('div', {
      domProps: {
        "innerHTML": _vm._s(trait.description)
      }
    })]);
  }), _c('v-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var attrs = _ref2.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.newItem();
            }
          }
        }, 'v-btn', attrs, false), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "pink darken-4"
    }
  }, [_vm._v("Edit Trait")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "justify": "space-around",
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "hide-details": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": ['Turn', 'Next Turn', 'Round', 'Next Round', 'Scene', 'Encounter', 'Mission'],
      "label": "Use",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.use,
      callback: function callback($$v) {
        _vm.use = $$v;
      },
      expression: "use"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "Description"
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-2"
  }), _c('v-row', {
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('i-action-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-bonus-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-counter-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-deployable-builder', {
    attrs: {
      "item": this
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('i-synergy-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('integrated-selector', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('special-equipment-selector', {
    attrs: {
      "item": this
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("cancel")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "success darken-2",
      "disabled": !_vm.confirmOK
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? 'save' : 'confirm') + " ")])], 1)], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }