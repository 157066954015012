











































import Vue from 'vue'
export default Vue.extend({
  name: 'tiered-stat-input',
  props: ['value'],
  computed: {
    val: {
      get() {
        return [this.value]
      },
      set(val: Array<number>) {
        this.$emit('input', val)
      },
    },
  },
  data: () => ({
    sizes: [0.5, 1, 2, 3, 4],
  }),
  methods: {
    setTier(evt: string, tier: number) {
      let e = evt ? evt : [1]
      if (!Array.isArray(e)) e = [Number(e)]
      e = e.map(v => Number(v))
      this.$set(this.val, tier, e)
    },
  },
})
