var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "caption mb-n1 mt-n3"
  }, [_vm._v("TAGS")]), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm._l(_vm.item.tags, function (tag, i) {
    return _c('v-chip', {
      key: "tag_chip_".concat(_vm.item.id, "-").concat(i),
      staticClass: "mx-1",
      attrs: {
        "small": "",
        "close": "",
        "outlined": "",
        "close-icon": "mdi-close"
      },
      on: {
        "click": function click($event) {
          return _vm.edit(tag, i);
        },
        "click:close": function clickClose($event) {
          return _vm.remove(i);
        }
      }
    }, [_vm._v(" " + _vm._s(tag.id) + ", " + _vm._s(tag.val) + " ")]);
  }), _c('v-menu', {
    attrs: {
      "close-on-click": false,
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }]),
    model: {
      value: _vm.menu,
      callback: function callback($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "pink darken-4"
    }
  }, [_vm._v("Add tag")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "justify": "space-around",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-select', {
    attrs: {
      "item-text": "name",
      "item-value": "id",
      "label": "Tag",
      "items": _vm.tags,
      "hide-details": ""
    },
    model: {
      value: _vm.tag.id,
      callback: function callback($$v) {
        _vm.$set(_vm.tag, "id", $$v);
      },
      expression: "tag.id"
    }
  })], 1), _c('v-col', [_vm.npc ? _c('tiered-stat-input', {
    attrs: {
      "title": "Value"
    },
    model: {
      value: _vm.tag.val,
      callback: function callback($$v) {
        _vm.$set(_vm.tag, "val", $$v);
      },
      expression: "tag.val"
    }
  }) : _c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Value",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.tag.val,
      callback: function callback($$v) {
        _vm.$set(_vm.tag, "val", $$v);
      },
      expression: "tag.val"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.menu = false;
      }
    }
  }, [_vm._v("cancel")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "success darken-2",
      "disabled": !_vm.tag.id
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? 'save' : 'confirm') + " ")])], 1)], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }