




















































































import Vue from 'vue'
import { ICounterData } from '@tenebrae-press/lancer-types'

type CounterBuilderData = {
  dialog: boolean
  counter: ICounterData
  isEdit: boolean
  editIndex: number
}

export default Vue.extend({
  name: 'counter-builder',
  props: { item: { type: Object, required: true } },
  data: (): CounterBuilderData => ({
    dialog: false,
    counter: { id: '', name: '' },
    isEdit: false,
    editIndex: -1,
  }),
  methods: {
    submit() {
      if (!this.counter) return
      if (this.isEdit) {
        this.$set(this.item.counters, this.editIndex, this.counter)
      } else {
        if (!this.item.counters) this.$set(this.item, 'counters', [])
        this.item.counters.push(this.counter)
      }
      this.$set(this, 'counter', {})
      this.isEdit = false
      this.editIndex = -1
      this.dialog = false
    },
    edit(counter: ICounterData, index: number) {
      this.counter = { ...counter }
      this.isEdit = true
      this.editIndex = index
      this.dialog = true
    },
    remove(index: number) {
      this.item.counters.splice(index, 1)
    },
  },
})
