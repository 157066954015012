var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    attrs: {
      "label": "ID",
      "hide-details": "",
      "prepend-icon": "mdi-dice-6"
    },
    on: {
      "click:prepend": _vm.uuid
    },
    model: {
      value: _vm.val,
      callback: function callback($$v) {
        _vm.val = $$v;
      },
      expression: "val"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }