





















































import Vue from 'vue'
import { DamageType, DAMAGE_TYPES, IDamageData } from '@tenebrae-press/lancer-types'
import { damageType } from '@/assets/enums'

export default Vue.extend({
  name: 'damage-selector',
  props: { item: { type: Object, required: true }, npc: { type: Boolean } },
  data: () => ({
    menu: false,
    damage: {} as IDamageData,
    isEdit: false,
    editIndex: -1,
    damageTypes: damageType,
  }),
  methods: {
    getColor(type: string) {
      switch (`${type.charAt(0).toUpperCase()}${type.substring(1)}`) {
        case 'Energy':
          return 'blue darken-2'
        case 'Explosive':
          return 'yellow darken-4'
        case 'Heat':
          return 'red darken-2'
        case 'Burn':
          return 'pink darken-4'
        case 'Variable':
          return 'purple darken-3'
        case 'Kinetic':
        default:
          return ''
      }
    },
    sanitizedDamageType(dt: string): DamageType {
      return (
        DAMAGE_TYPES.find(bt => {
          return bt === `${dt.charAt(0).toUpperCase()}${dt.substring(1)}`
        }) ?? 'Variable'
      )
    },
    submit() {
      if (!this.damage) return
      if (this.isEdit) {
        this.$set(this.item.damage, this.editIndex, this.damage)
      } else {
        if (!this.item.damage) this.$set(this.item, 'damage', [])
        this.item.damage.push(this.damage)
      }
      this.reset()
    },
    edit(damage: IDamageData, index: number) {
      const damageType = this.sanitizedDamageType(damage.type)

      this.damage = {
        ...damage,
        type: damageType,
      }
      this.isEdit = true
      this.editIndex = index
      this.menu = true
    },
    remove(index: number) {
      this.item.damage.splice(index, 1)
    },
    reset() {
      this.$set(this, 'damage', {})
      this.isEdit = false
      this.editIndex = -1
      this.menu = false
    },
  },
})
