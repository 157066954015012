var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "ml-1 mt-n2"
  }, [_vm._v("WEAPON PROFILES")]), _c('v-container', _vm._l(_vm.item.profiles, function (profile, i) {
    return _c('v-card', {
      key: "profile_card_".concat(i),
      staticClass: "pa-2 my-1"
    }, [_c('v-toolbar', {
      staticClass: "text-h6",
      attrs: {
        "dense": ""
      }
    }, [_vm._v(" " + _vm._s(profile.name || '---') + " "), _c('v-spacer'), _vm._v(" " + _vm._s(i === 0 ? 'Default' : '') + " Weapon Profile ")], 1), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: profile.effect,
        expression: "profile.effect"
      }],
      staticClass: "caption"
    }, [_vm._v("EFFECT")]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: profile.effect,
        expression: "profile.effect"
      }],
      domProps: {
        "innerHTML": _vm._s(profile.effect)
      }
    }), _vm._l(profile.damage, function (d, i) {
      return _c('div', {
        key: "damage_".concat(i)
      }, [_vm._v(" " + _vm._s(d.val) + " " + _vm._s(d.type) + " Damage ")]);
    }), _vm._l(profile.range, function (r, j) {
      return _c('div', {
        key: "range_".concat(j)
      }, [_vm._v(" " + _vm._s(r.val) + " " + _vm._s(r.type) + " ")]);
    })], 2), _c('v-col', [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: profile.on_attack,
        expression: "profile.on_attack"
      }],
      staticClass: "caption"
    }, [_vm._v("ON ATTACK")]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: profile.on_attack,
        expression: "profile.on_attack"
      }],
      domProps: {
        "innerHTML": _vm._s(profile.on_attack)
      }
    }), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: profile.on_hit,
        expression: "profile.on_hit"
      }],
      staticClass: "caption"
    }, [_vm._v("ON HIT")]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: profile.on_hit,
        expression: "profile.on_hit"
      }],
      domProps: {
        "innerHTML": _vm._s(profile.on_hit)
      }
    }), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: profile.on_crit,
        expression: "profile.on_crit"
      }],
      staticClass: "caption"
    }, [_vm._v("ON CRIT")]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: profile.on_crit,
        expression: "profile.on_crit"
      }],
      domProps: {
        "innerHTML": _vm._s(profile.on_crit)
      }
    })])], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
      attrs: {
        "color": "error"
      },
      on: {
        "click": function click($event) {
          return _vm.remove(i);
        }
      }
    }, [_vm._v("Delete")]), _c('v-spacer'), _c('v-btn', {
      attrs: {
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.edit(profile, i);
        }
      }
    }, [_vm._v("Edit")])], 1)], 1);
  }), 1), _c('v-btn', {
    attrs: {
      "block": "",
      "color": "primary darken-3"
    },
    on: {
      "click": _vm.open
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v(" Add Weapon Profile ")], 1), _c('v-dialog', {
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "pink darken-4"
    }
  }, [_vm._v("Edit Weapon Profile")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "justify": "space-around",
      "align": "end"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "hide-details": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Use Cost",
      "type": "number",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.cost,
      callback: function callback($$v) {
        _vm.cost = $$v;
      },
      expression: "cost"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "label": "Type",
      "items": _vm.weaponTypes,
      "hide-details": ""
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "Effect"
    },
    model: {
      value: _vm.effect,
      callback: function callback($$v) {
        _vm.effect = $$v;
      },
      expression: "effect"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "On Attack Effect"
    },
    model: {
      value: _vm.on_attack,
      callback: function callback($$v) {
        _vm.on_attack = $$v;
      },
      expression: "on_attack"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "On Hit Effect"
    },
    model: {
      value: _vm.on_hit,
      callback: function callback($$v) {
        _vm.on_hit = $$v;
      },
      expression: "on_hit"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "On Crit Effect"
    },
    model: {
      value: _vm.on_crit,
      callback: function callback($$v) {
        _vm.on_crit = $$v;
      },
      expression: "on_crit"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('damage-selector', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('range-selector', {
    attrs: {
      "item": this
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('i-action-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-bonus-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-deployable-builder', {
    attrs: {
      "item": this
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('i-synergy-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-counter-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('integrated-selector', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('special-equipment-selector', {
    attrs: {
      "item": this
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('tag-selector', {
    attrs: {
      "item": this
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("cancel")]), _c('v-spacer'), _vm.isEdit ? _c('v-btn', {
    attrs: {
      "color": "error darken-2"
    },
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("Delete")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "success darken-2",
      "disabled": !_vm.confirmOK
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? 'save' : 'confirm') + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }