































































































































import SynergySelector from './SynergyLocationSelector.vue'
import RangeSelector from './RangeSelector.vue'

import Vue from 'vue'
import Lancer, {
  ActivationType,
  IActionData,
  IDamageData,
  IRangeData,
} from '@tenebrae-press/lancer-types'
import { activationTypes } from '@/assets/enums'

type ActionBuilderDataType = {
  dialog: boolean
  id: string
  name: string
  activation: ActivationType | ''
  detail: string
  cost: number | null
  pilot: boolean
  synergy_locations: Array<string>
  damage: Array<IDamageData>
  range: Array<IRangeData>
  tech_attack: boolean
  frequency: 'Unlimited'
  init: string
  trigger: ''
  frequencies: ['Unlimited', '1/round', '1/encounter', '1/mission']
  isEdit: boolean
  editIndex: number
}

export default Vue.extend({
  name: 'action-builder',
  props: { item: { type: Object, required: true }, npc: { type: Boolean } },
  components: { SynergySelector, RangeSelector },
  data: (): ActionBuilderDataType => ({
    dialog: false,
    id: '',
    name: '',
    activation: '',
    detail: '',
    cost: 0,
    pilot: false,
    synergy_locations: [],
    damage: [],
    range: [],
    tech_attack: false,
    frequency: 'Unlimited',
    init: '',
    trigger: '',
    frequencies: ['Unlimited', '1/round', '1/encounter', '1/mission'],
    isEdit: false,
    editIndex: -1,
  }),
  computed: {
    confirmOK(): boolean {
      if (this.activation === 'Reaction') return !!this.detail && !!this.frequency && !!this.trigger
      return !!this.activation && !!this.detail
    },
  },
  methods: {
    sanitizeActivation(desc: string): ActivationType {
      return (
        (activationTypes.find(type => type.desc === desc)?.value as ActivationType) ??
        Lancer.ACTIVATION_TYPES[0]
      )
    },
    newItem(): void {
      this.reset()
      this.dialog = true
    },
    submit(): void {
      const e: IActionData = {
        id: this.id,
        name: this.name,
        activation: this.sanitizeActivation(this.activation),
        detail: this.detail,
        cost: this.cost ?? 0,
        pilot: this.pilot,
        damage: this.damage,
        range: this.range,
        synergy_locations: this.synergy_locations,
        tech_attack: this.tech_attack,
        frequency: this.frequency,
        init: this.init,
        trigger: this.trigger,
      }
      if (this.isEdit) {
        this.$set(this.item.actions, this.editIndex, e)
      } else {
        if (!this.item.actions) this.$set(this.item, 'actions', [])
        this.item.actions.push(e)
      }
      this.reset()
      this.dialog = false
    },
    edit(action: ActionBuilderDataType, index: number): void {
      this.reset()
      this.id = action.id
      this.name = action.name
      this.activation = action.activation
      this.detail = action.detail
      this.cost = action.cost ?? 0
      this.pilot = action.pilot ?? false
      this.damage = action.damage ?? []
      this.range = action.range ?? []
      this.synergy_locations = action.synergy_locations ?? []
      this.tech_attack = action.tech_attack ?? []
      this.frequency = action.frequency ?? []
      this.init = action.init ?? []
      this.trigger = action.trigger
      this.isEdit = true
      this.editIndex = index
      this.dialog = true
    },
    remove(index: number): void {
      this.item.actions.splice(index, 1)
    },
    reset(): void {
      this.id = ''
      this.name = ''
      this.activation = ''
      this.detail = ''
      this.cost = null
      this.pilot = false
      this.damage = []
      this.range = []
      this.synergy_locations = []
      this.tech_attack = false
      this.frequency = 'Unlimited'
      this.init = ''
      this.trigger = ''
      this.isEdit = false
      this.editIndex = -1
    },
  },
})
