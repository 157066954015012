
























































































































import Vue from 'vue'
import _ from 'lodash'
import PromisifyFileReader from 'promisify-file-reader'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import Lancer, {
  ILCPContent,
  IManifest,
  LCPContentKeys,
  LCPContentTypes,
} from '@tenebrae-press/lancer-types'

const skipCategories = [
  'manufacturers',
  'frames',
  'weapons',
  'systems',
  'mods',
  'core_bonuses',
  'npc_features',
]
const gmCategories = ['npc_classes', 'npc_templates']

export default Vue.extend({
  name: 'Home',
  data: () => ({
    lcpFile: null,
    loading: false,
    categories: Lancer.LCP_CONTENT_KEYS.filter(
      key => !gmCategories.includes(key) && !skipCategories.includes(key)
    ),
    gmCategories: Lancer.LCP_CONTENT_KEYS.filter(
      key => gmCategories.includes(key) && !skipCategories.includes(key)
    ),
  }),
  computed: {
    loaded(): boolean {
      return this.$store.getters.loaded
    },
    lcp(): ILCPContent {
      return this.$store.getters.lcp
    },
    manuCount(): number {
      if (!this.lcp.manufacturers && !this.lcp.frames) return 0
      const m =
        this.lcp.manufacturers && this.lcp.manufacturers.length
          ? this.lcp.manufacturers.map(x => x.id)
          : []
      const f = this.lcp.frames ? _.uniq(this.lcp.frames.map(x => x.source)) : []
      return _.uniq(m.concat(f)).length
    },
  },
  methods: {
    catLength(type: LCPContentKeys) {
      if (this.lcp[type]) return this.lcp[type]?.length
      return 0
    },

    async loadLcp(file: HTMLInputElement) {
      this.loading = true

      if (!file) return

      const fileData = await PromisifyFileReader.readAsBinaryString(file)
      try {
        this.$store.dispatch('loadLcp', fileData).then(() => {
          this.loading = false
        })
      } catch (e: unknown) {
        console.error(e)
        this.loading = false
      }
    },
    clearLcp() {
      this.$store.dispatch('clearLcp')
    },
    createNew() {
      this.$store.dispatch('clearLcp')
      this.$store.dispatch('newLcp')
    },
    exportLCP() {
      const filename = `${this.lcp.lcp_manifest.name.toLowerCase().replaceAll(' ', '-')}_${
        this.lcp.lcp_manifest.version
      }.lcp`
      const zip = new JSZip()
      Lancer.LCP_CONTENT_KEYS.forEach((key: LCPContentKeys) => {
        if (this.lcp[key] && this.lcp[key]?.length) {
          zip.file(`${key}.json`, this.prepareJSON(this.lcp[key] ?? []))
        }
      })
      zip.file('lcp_manifest.json', this.prepareJSON(this.lcp.lcp_manifest))
      if (this.lcp.tables) {
        zip.file('tables.json', this.prepareJSON(this.lcp.tables))
      }
      zip.generateAsync({ type: 'blob' }).then(function (blob) {
        saveAs(blob, filename)
      })
    },
    prepareJSON(obj: LCPContentTypes | IManifest | Record<string, unknown>): string {
      const d = JSON.stringify(obj)
      // tiptap's default <p> wrapping doesn't look good in C/C
      d.replaceAll('<p', '<div')
      d.replaceAll('</p', '</div')

      return d
    },
  },
})
