



























































import Vue from 'vue'
import { tags } from 'lancer-data'
import TieredStatInput from './TieredStatInput.vue'
import { ITagData } from '@tenebrae-press/lancer-types'

type TagSelectorData = {
  menu: boolean
  tag: ITagData
  isEdit: boolean
  editIndex: number
}

export default Vue.extend({
  name: 'tag-selector',
  props: { item: { type: Object, required: true }, npc: { type: Boolean } },
  components: { TieredStatInput },
  data: (): TagSelectorData => ({
    menu: false,
    tag: { id: '', name: '', description: '' },
    isEdit: false,
    editIndex: -1,
  }),
  computed: {
    tags() {
      const localTags = this.$store.getters.lcp.tags || []
      return tags.concat(localTags)
    },
  },
  methods: {
    submit() {
      if (!this.tag) return
      if (this.isEdit) {
        this.$set(this.item.tags, this.editIndex, this.tag)
      } else {
        if (!this.item.tags) this.$set(this.item, 'tags', [])
        this.item.tags.push(this.tag)
      }
      this.$set(this, 'tag', {})
      this.isEdit = false
      this.editIndex = -1
      this.menu = false
    },
    edit(tag: ITagData, index: number) {
      this.tag = { ...tag }
      this.isEdit = true
      this.editIndex = index
      this.menu = true
    },
    remove(index: number) {
      this.item.tags.splice(index, 1)
    },
  },
})
