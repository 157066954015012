

































































































import { synergyLocations, npcSynergyLocations } from '@/assets/enums'
import RichTextEditor from './RichTextEditor.vue'

import Vue from 'vue'
import Lancer, {
  ISynergyData,
  SystemType,
  WeaponSize,
  WeaponType,
} from '@tenebrae-press/lancer-types'

type SynergyBuilderData = {
  synergies: Array<{
    value: string
    desc: string
  }>
  npcSynergies: Array<{
    value: string
    desc: string
  }>
  weaponType: Array<WeaponType>
  weaponSize: Array<WeaponSize>
  systemType: Array<SystemType>
  detail: string
  locations: Array<string>
  editIndex: number
  dialog: boolean
  isEdit: boolean
  wt: Array<WeaponType>
  ws: Array<WeaponSize>
  st: Array<SystemType>
}

export default Vue.extend({
  name: 'synergy-builder',
  components: { RichTextEditor },
  props: { item: { type: Object, required: true }, npc: { type: Boolean } },
  data: (): SynergyBuilderData => ({
    synergies: synergyLocations,
    npcSynergies: npcSynergyLocations,
    weaponType: Lancer.WEAPON_TYPES,
    weaponSize: Lancer.WEAPON_SIZES,
    systemType: Lancer.SYSTEM_TYPES,
    dialog: false,
    locations: [],
    detail: '',
    wt: [],
    ws: [],
    st: [],
    isEdit: false,
    editIndex: -1,
  }),
  methods: {
    newItem(): void {
      this.reset()
      this.dialog = true
    },
    submit() {
      const e: ISynergyData = {
        locations: this.locations,
        detail: this.detail,
        weapon_types: this.wt,
        weapon_sizes: this.ws,
        system_types: this.st,
      }
      if (this.isEdit) {
        this.$set(this.item.synergies, this.editIndex, e)
      } else {
        if (!this.item.synergies) this.$set(this.item, 'synergies', [])
        this.item.synergies.push(e)
      }
      this.reset()
      this.dialog = false
    },
    edit(synergy: SynergyBuilderData, index: number): void {
      this.reset()
      this.locations = synergy.locations
      this.detail = synergy.detail
      this.wt = synergy.wt
      this.ws = synergy.ws
      this.st = synergy.st
      this.isEdit = true
      this.editIndex = index
      this.dialog = true
    },
    remove(index: number) {
      this.item.synergies.splice(index, 1)
    },
    descById(id: string) {
      return this.synergies.find(x => x.value === id)?.desc || 'err'
    },
    reset() {
      this.locations = []
      this.detail = ''
      this.wt = []
      this.ws = []
      this.st = []
    },
  },
})
