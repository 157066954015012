var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "caption mb-n1 mt-n3"
  }, [_vm._v("DEPLOYABLES")]), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm._l(_vm.item.deployables, function (deployable, i) {
    return _c('v-tooltip', {
      key: "deployable_chip_".concat(_vm.item.id, "-").concat(i),
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('v-chip', _vm._g({
            staticClass: "mx-1",
            attrs: {
              "small": "",
              "close": "",
              "outlined": "",
              "close-icon": "mdi-close"
            },
            on: {
              "click": function click($event) {
                return _vm.edit(deployable, i);
              },
              "click:close": function clickClose($event) {
                return _vm.remove(i);
              }
            }
          }, on), [_vm._v(" " + _vm._s(deployable.name) + " (" + _vm._s(deployable.type) + ") ")])];
        }
      }], null, true)
    }, [_c('div', [_vm._v(" Activation: " + _vm._s(deployable.activation) + " // Deactivation: " + _vm._s(deployable.deactivation || '--') + " // Recall: " + _vm._s(deployable.recall || '--') + " // Redeploy: " + _vm._s(deployable.redeploy || '--') + " ")]), _c('div', {
      domProps: {
        "innerHTML": _vm._s(deployable.detail)
      }
    }), _c('v-divider'), deployable.cost ? _c('div', [_vm._v(" Costs " + _vm._s(deployable.cost) + " Uses, " + _vm._s(deployable.instances) + " Instances ")]) : _vm._e(), deployable.pilot ? _c('div', [_vm._v(" Available to Pilot: "), _c('b', {
      staticClass: "warning--text"
    }, [_vm._v("true")])]) : _vm._e(), _c('div', [_c('v-divider', {
      staticClass: "my-1"
    }), _c('v-chip', {
      staticClass: "mx-1",
      attrs: {
        "outlined": "",
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s("".concat(deployable.armor ? "  Armor: ".concat(deployable.armor) : ''))
      }
    }), _c('v-chip', {
      staticClass: "mx-1",
      attrs: {
        "outlined": "",
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s("".concat(deployable.hp ? "  HP: ".concat(deployable.hp) : ''))
      }
    }), _c('v-chip', {
      staticClass: "mx-1",
      attrs: {
        "outlined": "",
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s("".concat(deployable.evasion ? "  Evasion: ".concat(deployable.evasion) : ''))
      }
    }), _c('v-chip', {
      staticClass: "mx-1",
      attrs: {
        "outlined": "",
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s("".concat(deployable.edef ? "  EDef: ".concat(deployable.edef) : ''))
      }
    }), _c('v-chip', {
      staticClass: "mx-1",
      attrs: {
        "outlined": "",
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s("".concat(deployable.heatcap ? "  Heatcap: ".concat(deployable.heatcap) : ''))
      }
    }), _c('v-chip', {
      staticClass: "mx-1",
      attrs: {
        "outlined": "",
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s("".concat(deployable.repcap ? "  Repcap: ".concat(deployable.repcap) : ''))
      }
    }), _c('v-chip', {
      staticClass: "mx-1",
      attrs: {
        "outlined": "",
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s("".concat(deployable.sensor_range ? "  Sensors: ".concat(deployable.sensor_range) : ''))
      }
    }), _c('v-chip', {
      staticClass: "mx-1",
      attrs: {
        "outlined": "",
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s("".concat(deployable.tech_attack ? "  Tech Atk: ".concat(deployable.tech_attack) : ''))
      }
    }), _c('v-chip', {
      staticClass: "mx-1",
      attrs: {
        "outlined": "",
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s("".concat(deployable.save ? "  Save: ".concat(deployable.save) : ''))
      }
    }), _c('v-chip', {
      staticClass: "mx-1",
      attrs: {
        "outlined": "",
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s("".concat(deployable.speed ? "  Speed: ".concat(deployable.speed) : ''))
      }
    }), _c('v-chip', {
      staticClass: "mx-1",
      attrs: {
        "outlined": "",
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s("".concat(deployable.size ? "  Size: ".concat(deployable.size) : ''))
      }
    })], 1), deployable.tags ? _c('div', [_c('v-divider'), _vm._l(deployable.tags, function (t) {
      return _c('v-chip', {
        key: t.id,
        attrs: {
          "small": ""
        }
      }, [_vm._v(_vm._s(t))]);
    })], 2) : _vm._e()], 1);
  }), _c('v-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var attrs = _ref2.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.newItem();
            }
          }
        }, 'v-btn', attrs, false), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "pink darken-4"
    }
  }, [_vm._v("Add Deployable")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "justify": "space-around",
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "hide-details": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Deployable Type",
      "hide-details": ""
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "label": "Size",
      "items": [0.5, 1, 2, 3, 4],
      "hide-details": ""
    },
    model: {
      value: _vm.size,
      callback: function callback($$v) {
        _vm.size = $$v;
      },
      expression: "size"
    }
  })], 1), !_vm.npc ? _c('v-col', [_c('i-synergy-builder', {
    attrs: {
      "item": this
    }
  })], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "Detail",
      "npc": ""
    },
    model: {
      value: _vm.detail,
      callback: function callback($$v) {
        _vm.detail = $$v;
      },
      expression: "detail"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('tag-selector', {
    attrs: {
      "item": _vm.item
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('activator-selector', {
    attrs: {
      "item": this,
      "label": "Activation"
    }
  })], 1), _c('v-col', [_c('activator-selector', {
    attrs: {
      "item": this,
      "label": "Deactivation",
      "field": "deactivation",
      "optional": ""
    }
  })], 1), _c('v-col', [_c('activator-selector', {
    attrs: {
      "item": this,
      "label": "Recall",
      "field": "recall",
      "optional": ""
    }
  })], 1), _c('v-col', [_c('activator-selector', {
    attrs: {
      "item": this,
      "label": "Redeploy",
      "field": "redeploy",
      "optional": ""
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "my-4",
    attrs: {
      "dense": "",
      "align": "center",
      "justify": "space-around"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Instances",
      "dense": "",
      "clearable": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.instances,
      callback: function callback($$v) {
        _vm.instances = $$v;
      },
      expression: "instances"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Use Cost",
      "dense": "",
      "clearable": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.cost,
      callback: function callback($$v) {
        _vm.cost = $$v;
      },
      expression: "cost"
    }
  })], 1), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.npc,
      expression: "!npc"
    }],
    staticClass: "mt-n4"
  }, [_c('v-switch', {
    attrs: {
      "label": "Pilot",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.pilot,
      callback: function callback($$v) {
        _vm.pilot = $$v;
      },
      expression: "pilot"
    }
  })], 1), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.npc,
      expression: "!npc"
    }],
    staticClass: "mt-n4"
  }, [_c('v-switch', {
    attrs: {
      "label": "Mech",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.mech,
      callback: function callback($$v) {
        _vm.mech = $$v;
      },
      expression: "mech"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "my-2",
    attrs: {
      "dense": "",
      "align": "center",
      "justify": "space-around"
    }
  }, _vm._l(_vm.stats, function (k) {
    return _c('v-col', {
      key: "dep_stat_".concat(k),
      attrs: {
        "cols": "2"
      }
    }, [_vm.npc ? _c('tiered-stat-input', {
      attrs: {
        "title": k.replaceAll('_', ' ')
      },
      model: {
        value: _vm.$data[k],
        callback: function callback($$v) {
          _vm.$set(_vm.$data, k, $$v);
        },
        expression: "$data[k]"
      }
    }) : _c('v-text-field', {
      attrs: {
        "type": "number",
        "label": k.replaceAll('_', ' '),
        "dense": "",
        "clearable": "",
        "outlined": "",
        "hide-details": ""
      },
      model: {
        value: _vm.$data[k],
        callback: function callback($$v) {
          _vm.$set(_vm.$data, k, $$v);
        },
        expression: "$data[k]"
      }
    })], 1);
  }), 1), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-row', {
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('i-action-builder', {
    attrs: {
      "item": this,
      "npc": ""
    }
  })], 1), _c('v-col', [_c('i-bonus-builder', {
    attrs: {
      "item": this,
      "npc": ""
    }
  })], 1), _c('v-col', [_c('i-counter-builder', {
    attrs: {
      "item": this
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("cancel")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "success darken-2",
      "disabled": !_vm.confirmOK
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? 'save' : 'confirm') + " ")])], 1)], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }