






















































import Vue from 'vue'

export default Vue.extend({
  name: 'App',
  data: () => ({
    categories: [
      'manufacturers',
      'actions',
      'backgrounds',
      'pilot_gear',
      'reserves',
      'skills',
      'statuses',
      'tables',
      'tags',
      'talents',
      'sitreps',
      'environments',
    ],
    gmCategories: ['npc_classes', 'npc_templates'],
  }),
  created() {
    this.$vuetify.theme.dark = true
  },
  computed: {
    loaded(): boolean {
      return this.$store.getters.loaded
    },
  },
})
