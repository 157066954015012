var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', {
    attrs: {
      "label": "".concat(_vm.label).concat(_vm.optional ? ' (Optional)' : ''),
      "items": _vm.activationTypes,
      "item-text": "desc",
      "hide-details": "",
      "clearable": _vm.optional
    },
    model: {
      value: _vm.item[_vm.field],
      callback: function callback($$v) {
        _vm.$set(_vm.item, _vm.field, $$v);
      },
      expression: "item[field]"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }