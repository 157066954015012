

























































import Vue from 'vue'

type ClockData = {
  id: string
  name: string
  min: number
  max: number
}

type ClockBuilderData = {
  dialog: boolean
  clock: ClockData
  isEdit: boolean
  editIndex: number
}

export default Vue.extend({
  name: 'clock-builder',
  props: { item: { type: Object, required: true } },
  data: (): ClockBuilderData => ({
    dialog: false,
    clock: { id: '', name: '', min: 0, max: 0 },
    isEdit: false,
    editIndex: -1,
  }),
  methods: {
    submit() {
      if (!this.clock) return
      if (this.isEdit) {
        this.$set(this.item.clocks, this.editIndex, this.clock)
      } else {
        if (!this.item.clocks) this.$set(this.item, 'clocks', [])
        this.item.clocks.push(this.clock)
      }
      this.$set(this, 'clock', {})
      this.isEdit = false
      this.editIndex = -1
      this.dialog = false
    },
    edit(clock: ClockData, index: number) {
      this.clock = { ...clock }
      this.isEdit = true
      this.editIndex = index
      this.dialog = true
    },
    remove(index: number) {
      this.item.clocks.splice(index, 1)
    },
  },
})
