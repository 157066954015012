var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "caption mb-n1 mt-n3"
  }, [_vm._v("TALENT RANKS")]), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm._l(_vm.item.ranks, function (rank, i) {
    return _c('v-tooltip', {
      key: "rank_chip_".concat(_vm.item.name, "-").concat(i),
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('v-chip', _vm._g({
            staticClass: "ma-2",
            attrs: {
              "large": "",
              "label": "",
              "close": "",
              "color": "primary darken-3"
            },
            on: {
              "click": function click($event) {
                return _vm.edit(rank, i);
              },
              "click:close": function clickClose($event) {
                return _vm.remove(i);
              }
            }
          }, on), [_vm._v(" RANK " + _vm._s('I'.repeat(i + 1)) + " // " + _vm._s(rank.name) + " ")])];
        }
      }], null, true)
    }, [_c('div', {
      domProps: {
        "innerHTML": _vm._s(rank.description)
      }
    }), _c('v-divider'), rank.cost ? _c('div', [_vm._v("Costs " + _vm._s(rank.cost) + " Uses")]) : _vm._e(), rank.exclusive ? _c('div', [_vm._v(" Exclusive: "), _c('b', {
      staticClass: "warning--text"
    }, [_vm._v("true")])]) : _vm._e()], 1);
  }), _c('v-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var attrs = _ref2.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.newItem();
            }
          }
        }, 'v-btn', attrs, false), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "pink darken-4"
    }
  }, [_vm._v("Add Rank")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "justify": "space-around",
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "hide-details": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_c('v-switch', _vm._g({
          attrs: {
            "dense": "",
            "hide-details": "",
            "label": "Exclusive"
          },
          model: {
            value: _vm.exclusive,
            callback: function callback($$v) {
              _vm.exclusive = $$v;
            },
            expression: "exclusive"
          }
        }, on))];
      }
    }])
  }, [_vm._v(" The exclusive field determines if only the bonuses, deployables, counters, and integrated equipment from the highest unlocked rank will be equipped, or all unlocked ranks. For example, the system granted by Walking Armory is upgraded every rank. In C/C this is rendered by changing the granted system. Because the player is equipped with the \"latest\" system, based on talent rank, the talent_items in the Walking Armory ranks are all set to true. ")])], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "Description"
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('i-action-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-bonus-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-deployable-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-synergy-builder', {
    attrs: {
      "item": this
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('i-counter-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('integrated-selector', {
    attrs: {
      "item": this
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("cancel")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "success darken-2",
      "disabled": !_vm.confirmOK
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? 'save' : 'confirm') + " ")])], 1)], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }