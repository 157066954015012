var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', {
    attrs: {
      "label": "Special Equipment",
      "items": _vm.equipment,
      "item-text": "name",
      "item-value": "id",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "multiple": ""
    },
    model: {
      value: _vm.item.special_equipment,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "special_equipment", $$v);
      },
      expression: "item.special_equipment"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }