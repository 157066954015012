








































import Vue from 'vue'
export default Vue.extend({
  name: 'tiered-stat-input',
  props: ['value', 'title'],
  computed: {
    val: {
      get() {
        return [...this.value] as Array<number>
      },
      set(val: Array<number>) {
        this.$emit('input', val)
      },
    },
  },
  created() {
    if (!Array.isArray(this.val)) this.$set(this, 'val', [0, 0, 0])
  },
  methods: {
    setTier(evt: string, tier: number) {
      const n = Number(evt)
      if (!Array.isArray(this.val)) this.$set(this, 'val', [n, n, n])
      this.$set(this.val, tier, n)
    },
  },
})
