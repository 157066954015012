var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "caption mb-n1 mt-n3"
  }, [_vm._v("SYNERGIES")]), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm._l(_vm.item.synergies, function (synergy, i) {
    return _c('v-tooltip', {
      key: "synergy_chip_".concat(_vm.item.id, "-").concat(i),
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('v-chip', _vm._g({
            staticClass: "mx-1",
            attrs: {
              "small": "",
              "close": "",
              "outlined": "",
              "close-icon": "mdi-close"
            },
            on: {
              "click": function click($event) {
                return _vm.edit(synergy, i);
              },
              "click:close": function clickClose($event) {
                return _vm.remove(i);
              }
            }
          }, on), [_vm._v(" " + _vm._s(synergy.locations.join(', ')) + " ")])];
        }
      }], null, true)
    }, [_c('div', {
      domProps: {
        "innerHTML": _vm._s(synergy.detail)
      }
    })]);
  }), _c('v-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var attrs = _ref2.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.newItem();
            }
          }
        }, 'v-btn', attrs, false), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "pink darken-4"
    }
  }, [_vm._v("Add Synergy")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "justify": "space-around",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Synergy Locations",
      "items": _vm.npc ? _vm.npcSynergies : _vm.synergies,
      "item-value": "value",
      "item-text": "desc",
      "multiple": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.locations,
      callback: function callback($$v) {
        _vm.locations = $$v;
      },
      expression: "locations"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('rich-text-editor', {
    attrs: {
      "title": "Detail"
    },
    model: {
      value: _vm.detail,
      callback: function callback($$v) {
        _vm.detail = $$v;
      },
      expression: "detail"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.npc,
      expression: "!npc"
    }]
  }, [_c('v-divider', {
    staticClass: "mb-2 mt-3"
  }), _c('div', {
    staticClass: "caption"
  }, [_vm._v("Restrict to:")]), _c('v-row', {
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.weaponType,
      "dense": "",
      "outlined": "",
      "multiple": "",
      "hide-details": "",
      "label": "Weapon Types"
    },
    model: {
      value: _vm.wt,
      callback: function callback($$v) {
        _vm.wt = $$v;
      },
      expression: "wt"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.weaponSize,
      "dense": "",
      "outlined": "",
      "multiple": "",
      "hide-details": "",
      "label": "Weapon Sizes"
    },
    model: {
      value: _vm.ws,
      callback: function callback($$v) {
        _vm.ws = $$v;
      },
      expression: "ws"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.systemType,
      "dense": "",
      "outlined": "",
      "multiple": "",
      "hide-details": "",
      "label": "System Types"
    },
    model: {
      value: _vm.st,
      callback: function callback($$v) {
        _vm.st = $$v;
      },
      expression: "st"
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("cancel")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "success darken-2",
      "disabled": !_vm.locations.length || !_vm.detail
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? 'save' : 'confirm') + " ")])], 1)], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }