
















import Vue from 'vue'
export default Vue.extend({
  name: 'color-selector',
  props: {
    title: { type: String },
    disabled: { type: Boolean, default: false },
    value: { type: String },
  },
  computed: {
    val: {
      get(): string {
        return `${this.value}`
      },
      set(val: string) {
        this.$emit('input', val)
      },
    },
    color(): string {
      return (this.val as string) || ''
    },
  },
})
