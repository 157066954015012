var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', {
    staticClass: "mb-3",
    attrs: {
      "justify": "space-around",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-file-input', {
    attrs: {
      "filled": "",
      "color": "primary",
      "label": "Load LCP",
      "accept": ".lcp,.zip",
      "show-size": "",
      "hide-details": "",
      "loading": _vm.loading
    },
    on: {
      "click:clear": function clickClear($event) {
        return _vm.clearLcp();
      },
      "change": function change($event) {
        return _vm.loadLcp($event);
      }
    },
    model: {
      value: _vm.lcpFile,
      callback: function callback($$v) {
        _vm.lcpFile = $$v;
      },
      expression: "lcpFile"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.createNew
    }
  }, [_vm._v("Create New LCP")])], 1)], 1), !_vm.loaded ? _c('v-card', {
    key: _vm.loaded,
    attrs: {
      "color": "grey darken-2"
    }
  }, [_c('v-card-text', {
    staticClass: "text--disabled text-center"
  }, [_vm._v("No LCP loaded")])], 1) : _c('v-card', [_c('v-toolbar', {
    attrs: {
      "dense": "",
      "color": "pink darken-3",
      "dark": ""
    }
  }, [_c('span', {
    staticClass: "text-h4"
  }, [_vm._v(_vm._s(_vm.lcp.lcp_manifest.name))])]), _c('v-card-text', [_c('v-alert', {
    attrs: {
      "outlined": "",
      "text": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-h5"
  }, [_vm._v("LCP Manifest")]), _c('v-row', [_c('v-col', [_c('v-row', {
    attrs: {
      "dense": "",
      "justify": "space-around"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.lcp.lcp_manifest.name,
      callback: function callback($$v) {
        _vm.$set(_vm.lcp.lcp_manifest, "name", $$v);
      },
      expression: "lcp.lcp_manifest.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Author"
    },
    model: {
      value: _vm.lcp.lcp_manifest.author,
      callback: function callback($$v) {
        _vm.$set(_vm.lcp.lcp_manifest, "author", $$v);
      },
      expression: "lcp.lcp_manifest.author"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Item ID Prefix"
    },
    model: {
      value: _vm.lcp.lcp_manifest.item_prefix,
      callback: function callback($$v) {
        _vm.$set(_vm.lcp.lcp_manifest, "item_prefix", $$v);
      },
      expression: "lcp.lcp_manifest.item_prefix"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "LCP Version"
    },
    model: {
      value: _vm.lcp.lcp_manifest.version,
      callback: function callback($$v) {
        _vm.$set(_vm.lcp.lcp_manifest, "version", $$v);
      },
      expression: "lcp.lcp_manifest.version"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Website URL"
    },
    model: {
      value: _vm.lcp.lcp_manifest.website,
      callback: function callback($$v) {
        _vm.$set(_vm.lcp.lcp_manifest, "website", $$v);
      },
      expression: "lcp.lcp_manifest.website"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Preview Image URL"
    },
    model: {
      value: _vm.lcp.lcp_manifest.image_url,
      callback: function callback($$v) {
        _vm.$set(_vm.lcp.lcp_manifest, "image_url", $$v);
      },
      expression: "lcp.lcp_manifest.image_url"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "Description"
    },
    model: {
      value: _vm.lcp.lcp_manifest.description,
      callback: function callback($$v) {
        _vm.$set(_vm.lcp.lcp_manifest, "description", $$v);
      },
      expression: "lcp.lcp_manifest.description"
    }
  })], 1)], 1)], 1), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!_vm.lcp.lcp_manifest.image_url,
      expression: "!!lcp.lcp_manifest.image_url"
    }],
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" Image Preview "), _c('v-img', {
    attrs: {
      "src": _vm.lcp.lcp_manifest.image_url,
      "max-width": "300",
      "contain": ""
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "text-h6 mb-2"
  }, [_vm._v("LCP Contents:")]), _c('v-row', {
    attrs: {
      "justify": "space-around"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-large": "",
      "block": "",
      "color": "primary darken-3",
      "to": "editor/manufacturers"
    }
  }, [_vm._v(" Manufacturers & Licenses ")]), _c('br'), _c('div', {
    staticClass: "text-center mt-n4"
  }, [_vm._v(" " + _vm._s(_vm.manuCount) + " Manufacturers (" + _vm._s(_vm.catLength('manufacturers')) + " new) with " + _vm._s(_vm.catLength('frames')) + " Licenses, containing " + _vm._s(_vm.catLength('weapons')) + " Weapons, " + _vm._s(_vm.catLength('systems')) + " Systems and " + _vm._s(_vm.catLength('mods')) + " Weapon Mods ")])], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "space-around"
    }
  }, [_vm._l(_vm.categories, function (t, i) {
    return _c('v-col', {
      key: "player_btn_".concat(i),
      attrs: {
        "cols": "2"
      }
    }, [_c('v-btn', {
      attrs: {
        "large": "",
        "block": "",
        "color": "primary darken-3",
        "to": "editor/".concat(t)
      }
    }, [_vm._v(" " + _vm._s(t.replace('_', ' ')) + " "), _c('span', {
      staticClass: "item-count"
    }, [_vm._v("(" + _vm._s(_vm.catLength(t)) + ")")])])], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "to": 'editor/tables',
      "large": "",
      "block": "",
      "color": "primary darken-3"
    }
  }, [_vm._v(" " + _vm._s('Tables') + " ")])], 1)], 2), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-alert', {
    attrs: {
      "outlined": "",
      "color": "red",
      "icon": "mdi-alert"
    }
  }, [_vm._v(" The editors below are only compatible with the forthcoming GM tools update. They "), _c('b', [_vm._v("SHOULD NOT")]), _vm._v(" be used to create NPC data for the current version of COMP/CON ")]), _c('v-row', {
    attrs: {
      "justify": "space-around"
    }
  }, _vm._l(_vm.gmCategories, function (t, i) {
    return _c('v-col', {
      key: "gm_btn_".concat(i),
      attrs: {
        "cols": "2"
      }
    }, [_c('v-btn', {
      attrs: {
        "large": "",
        "block": "",
        "color": "primary darken-3",
        "to": "editor/".concat(t)
      }
    }, [_vm._v(" " + _vm._s(t.replace('_', ' ')) + " "), _c('span', {
      staticClass: "item-count"
    }, [_vm._v("(" + _vm._s(_vm.catLength(t)) + ")")])])], 1);
  }), 1)], 1), _c('v-divider', {
    staticClass: "my-2"
  }), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "x-large": "",
      "block": "",
      "color": "success"
    },
    on: {
      "click": _vm.exportLCP
    }
  }, [_vm._v("Export LCP")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }