var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    attrs: {
      "filled": "",
      "label": _vm.title,
      "dense": "",
      "hide-details": "",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.val,
      callback: function callback($$v) {
        _vm.val = $$v;
      },
      expression: "val"
    }
  }, [_c('v-avatar', {
    staticClass: "mt-n1 mr-n1",
    attrs: {
      "slot": "prepend",
      "color": _vm.color,
      "size": "33"
    },
    slot: "prepend"
  }), _c('v-menu', {
    attrs: {
      "slot": "append-outer",
      "bottom": "",
      "offset-y": "",
      "close-on-content-click": false
    },
    slot: "append-outer",
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mt-n1 ml-n2",
          attrs: {
            "icon": "",
            "disabled": _vm.disabled
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-eyedropper-plus")])], 1)];
      }
    }])
  }, [_c('v-card', [_c('v-color-picker', {
    attrs: {
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.val,
      callback: function callback($$v) {
        _vm.val = $$v;
      },
      expression: "val"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }