var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', {
    attrs: {
      "label": "Synergy Locations",
      "multiple": "",
      "dense": "",
      "clearable": "",
      "small-chips": "",
      "outlined": "",
      "deletable-chips": "",
      "hide-details": "",
      "items": _vm.synergyLocations.map(function (x) {
        return x.value;
      })
    },
    model: {
      value: _vm.item.synergy_locations,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "synergy_locations", $$v);
      },
      expression: "item.synergy_locations"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }