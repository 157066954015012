var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "caption mb-n1 mt-n3"
  }, [_vm._v("COUNTERS")]), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm._l(_vm.item.counters, function (counter, i) {
    return _c('v-tooltip', {
      key: "counter_chip_".concat(_vm.item.id, "-").concat(i),
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('v-chip', _vm._g({
            staticClass: "mx-1",
            attrs: {
              "small": "",
              "close": "",
              "outlined": "",
              "close-icon": "mdi-close"
            },
            on: {
              "click": function click($event) {
                return _vm.edit(counter, i);
              },
              "click:close": function clickClose($event) {
                return _vm.remove(i);
              }
            }
          }, on), [_vm._v(" " + _vm._s(counter.name) + " ")])];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(counter.id) + " "), _c('v-divider'), counter.min ? _c('div', [_vm._v("Min: " + _vm._s(counter.min))]) : _vm._e(), counter.max ? _c('div', [_vm._v("Max: " + _vm._s(counter.max))]) : _vm._e(), counter.default_value ? _c('div', [_vm._v("Starts At: " + _vm._s(counter.default_value))]) : _vm._e()], 1);
  }), _c('v-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "pink darken-4"
    }
  }, [_vm._v("Add Counter")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "justify": "space-around",
      "align": "center"
    }
  }, [_c('v-col', [_c('id-input', {
    model: {
      value: _vm.counter.id,
      callback: function callback($$v) {
        _vm.$set(_vm.counter, "id", $$v);
      },
      expression: "counter.id"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "hide-details": ""
    },
    model: {
      value: _vm.counter.name,
      callback: function callback($$v) {
        _vm.$set(_vm.counter, "name", $$v);
      },
      expression: "counter.name"
    }
  })], 1)], 1), _c('v-divider'), _c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "justify": "space-around",
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Min",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.counter.min,
      callback: function callback($$v) {
        _vm.$set(_vm.counter, "min", $$v);
      },
      expression: "counter.min"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Max",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.counter.max,
      callback: function callback($$v) {
        _vm.$set(_vm.counter, "max", $$v);
      },
      expression: "counter.max"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Starting Value",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.counter.default_value,
      callback: function callback($$v) {
        _vm.$set(_vm.counter, "default_value", $$v);
      },
      expression: "counter.default_value"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("cancel")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "success darken-2",
      "disabled": !_vm.counter.name || !_vm.counter.id
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? 'save' : 'confirm') + " ")])], 1)], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }