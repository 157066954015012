





























































































































































import Lancer, {
  DamageType,
  IBonusData,
  RangeType,
  WeaponSize,
  WeaponType,
} from '@tenebrae-press/lancer-types'
import { bonuses, npcBonuses } from '@/assets/enums'

import Vue from 'vue'
import TieredStatInput from './TieredStatInput.vue'

type BonusBuilderDataType = {
  bonuses: typeof bonuses
  npcBonuses: typeof npcBonuses
  weaponType?: Array<WeaponType>
  weaponSize?: Array<WeaponSize>
  damageType?: Array<DamageType>
  rangeType?: Array<RangeType>
  dialog: boolean
  bonus?: typeof bonuses[0] | null
  value?: string
  dt: Array<DamageType>
  wt: Array<WeaponType>
  rt: Array<RangeType>
  ws: Array<WeaponSize>
  overwrite: boolean
  replace: boolean
  isEdit: boolean
  editIndex: number
}

export default Vue.extend({
  components: { TieredStatInput },
  name: 'bonus-builder',
  props: { item: { type: Object, required: true }, npc: { type: Boolean } },
  data: (): BonusBuilderDataType => ({
    bonuses: bonuses,
    npcBonuses: npcBonuses,
    weaponType: Lancer.WEAPON_TYPES,
    weaponSize: Lancer.WEAPON_SIZES,
    damageType: Lancer.DAMAGE_TYPES,
    rangeType: Lancer.RANGE_TYPES,
    dialog: false,
    bonus: null,
    value: '',
    dt: [],
    wt: [],
    rt: [],
    ws: [],
    overwrite: false,
    replace: false,
    isEdit: false,
    editIndex: -1,
  }),
  methods: {
    newItem(): void {
      this.reset()
      this.dialog = true
    },
    submit() {
      if (!this.bonus) return
      const e = {
        id: (this.bonus as typeof bonuses[0]).value,
        val: this.value,
        damage_types: this.dt,
        range_types: this.rt,
        weapon_types: this.wt,
        weapon_sizes: this.ws,
        overwrite: this.overwrite,
        replace: this.replace,
      }
      if (this.isEdit) {
        this.$set(this.item.bonuses, this.editIndex, e)
      } else {
        if (!this.item.bonuses) this.$set(this.item, 'bonuses', [])
        this.item.bonuses.push(e)
      }
      this.reset()
      this.dialog = false
    },
    edit(bonus: IBonusData, index: number): void {
      this.reset()
      this.bonus = this.bonuses.find(x => x.value === bonus.id)
      this.value = bonus.val.toString()
      this.dt = bonus.damage_types ?? []
      this.rt = bonus.range_types ?? []
      this.wt = bonus.weapon_types ?? []
      this.ws = bonus.weapon_sizes ?? []
      this.overwrite = bonus.overwrite ?? false
      this.replace = bonus.replace ?? false
      this.isEdit = true
      this.editIndex = index
      this.dialog = true
    },
    remove(index: number) {
      this.item.bonuses.splice(index, 1)
    },
    descById(id: string) {
      return this.bonuses.find(x => x.value === id)?.desc || 'err'
    },
    restrictions(bonus: IBonusData) {
      return ([] as Array<WeaponSize | WeaponType | RangeType | DamageType>)
        .concat(bonus.damage_types ?? [])
        .concat(bonus.range_types ?? [])
        .concat(bonus.weapon_types ?? [])
        .concat(bonus.weapon_sizes ?? [])
    },
    reset() {
      this.bonus = null
      this.value = ''
      this.dt = this.wt = this.rt = this.ws = []
      this.overwrite = this.replace = this.isEdit = false
      this.editIndex = -1
    },
  },
})
