

















































































































































































































import { activationTypes } from '@/assets/enums'
import Lancer, {
  ActivationType,
  IActionData,
  IBonusData,
  ICounterData,
  IDeployableData,
  ISynergyData,
} from '@tenebrae-press/lancer-types'
import Vue from 'vue'
const stats = [
  'armor',
  'hp',
  'evasion',
  'edef',
  'heatcap',
  'repcap',
  'sensor_range',
  'tech_attack',
  'save',
  'speed',
]

type DeployableBuilderData = {
  stats: typeof stats
  dialog: boolean
  name: string
  type: string
  detail: string
  size: number
  activation: string
  deactivation: string
  recall: string
  redeploy: string
  instances: number
  cost: number
  armor?: number
  hp?: number
  evasion?: number
  edef?: number
  heatcap?: number
  repcap?: number
  sensor_range?: number
  tech_attack?: number
  save?: number
  speed?: number
  pilot: boolean
  mech: boolean
  actions: Array<IActionData>
  bonuses: Array<IBonusData>
  synergies: Array<ISynergyData>
  counters: Array<ICounterData>
  isEdit: boolean
  editIndex: number
}

export default Vue.extend({
  name: 'deployable-builder',
  props: { item: { type: Object, required: true }, npc: { type: Boolean } },

  data: (): DeployableBuilderData => ({
    stats: stats,
    dialog: false,
    name: '',
    type: '',
    detail: '',
    size: 1,
    activation: '',
    deactivation: '',
    recall: '',
    redeploy: '',
    instances: 1,
    cost: 1,
    pilot: false,
    mech: false,
    actions: [],
    bonuses: [],
    synergies: [],
    counters: [],
    isEdit: false,
    editIndex: -1,
  }),
  computed: {
    confirmOK(): boolean {
      return !!this.name && !!this.type && !!this.detail && !!this.size
    },
  },
  methods: {
    sanitizeActivation(desc: string): ActivationType {
      return (
        (activationTypes.find(type => type.desc === desc)?.value as ActivationType) ??
        Lancer.ACTIVATION_TYPES[0]
      )
    },
    newItem(): void {
      this.reset()
      this.dialog = true
    },
    submit(): void {
      const e: IDeployableData = {
        name: this.name,
        type: this.type,
        detail: this.detail,
        size: this.size,
        activation: this.sanitizeActivation(this.activation),
        deactivation: this.sanitizeActivation(this.deactivation),
        recall: this.sanitizeActivation(this.recall),
        redeploy: this.sanitizeActivation(this.redeploy),
        instances: this.instances,
        cost: this.cost,
        armor: this.armor,
        hp: this.hp,
        evasion: this.evasion,
        edef: this.edef,
        heatcap: this.heatcap,
        repcap: this.repcap,
        sensor_range: this.sensor_range,
        tech_attack: this.tech_attack,
        save: this.save,
        speed: this.speed,
        pilot: this.pilot,
        mech: this.mech,
        actions: this.actions,
        bonuses: this.bonuses,
        synergies: this.synergies,
        counters: this.counters,
      }
      if (this.isEdit) {
        this.$set(this.item.deployables, this.editIndex, e)
      } else {
        if (!this.item.deployables) this.$set(this.item, 'deployables', [])
        this.item.deployables.push(e)
      }
      this.reset()
      this.dialog = false
    },
    edit(deployable: DeployableBuilderData, index: number): void {
      this.reset()
      this.name = deployable.name || ''
      this.type = deployable.type || ''
      this.detail = deployable.detail || ''
      this.size = deployable.size || 1
      this.activation = deployable.activation || ''
      this.deactivation = deployable.deactivation || ''
      this.recall = deployable.recall || ''
      this.redeploy = deployable.redeploy || ''
      this.instances = deployable.instances || 1
      this.cost = deployable.cost || 1
      this.armor = deployable.armor || 0
      this.hp = deployable.hp
      this.evasion = deployable.evasion
      this.edef = deployable.edef
      this.heatcap = deployable.heatcap
      this.repcap = deployable.repcap
      this.sensor_range = deployable.sensor_range
      this.tech_attack = deployable.tech_attack
      this.save = deployable.save
      this.speed = deployable.speed
      this.pilot = deployable.pilot || false
      this.mech = deployable.mech || false
      this.actions = deployable.actions || []
      this.bonuses = deployable.bonuses || []
      this.synergies = deployable.synergies || []
      this.counters = deployable.counters || []
      this.isEdit = true
      this.editIndex = index
      this.dialog = true
    },
    remove(index: number): void {
      this.item.deployables.splice(index, 1)
    },
    reset(): void {
      this.name = ''
      this.type = ''
      this.detail = ''
      this.size = 1
      this.activation = ''
      this.deactivation = ''
      this.recall = ''
      this.redeploy = ''
      this.instances = 1
      this.cost = 1
      this.armor = undefined
      this.hp = undefined
      this.evasion = undefined
      this.edef = undefined
      this.heatcap = undefined
      this.repcap = undefined
      this.sensor_range = undefined
      this.tech_attack = undefined
      this.save = undefined
      this.speed = undefined
      this.pilot = false
      this.mech = false
      this.actions = []
      this.bonuses = []
      this.synergies = []
      this.counters = []
    },
  },
})
