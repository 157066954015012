




















































import Vue from 'vue'
import TieredStatInput from './TieredStatInput.vue'
import Lancer, { IRangeData, RangeType } from '@tenebrae-press/lancer-types'

type RangeSelectorData = {
  menu: boolean
  range: IRangeData
  isEdit: boolean
  editIndex: number
  rangeTypes: Array<RangeType>
}

export default Vue.extend({
  components: { TieredStatInput },
  name: 'range-selector',
  props: { item: { type: Object, required: true }, npc: { type: Boolean } },
  data: (): RangeSelectorData => ({
    menu: false,
    range: { type: '' as RangeType, val: 0 },
    isEdit: false,
    editIndex: -1,
    rangeTypes: Lancer.RANGE_TYPES,
  }),
  methods: {
    submit() {
      if (!this.range) return
      if (this.isEdit) {
        this.$set(this.item.range, this.editIndex, { ...this.range, val: Number(this.range.val) })
      } else {
        if (!this.item.range) this.$set(this.item, 'range', [])
        this.item.range.push({ ...this.range, val: Number(this.range.val) })
      }
      this.reset()
    },
    edit(range: IRangeData, index: number) {
      this.range = { ...range }
      this.isEdit = true
      this.editIndex = index
      this.menu = true
    },
    remove(index: number) {
      this.item.range.splice(index, 1)
    },
    reset() {
      this.$set(this, 'range', {})
      this.isEdit = false
      this.editIndex = -1
      this.menu = false
    },
  },
})
